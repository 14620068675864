.notification {
  border-radius: 0.1rem;
  padding: 16px 16px 16px 16px;
}

.notification:before {
  content: '';
  display: none;
}

.notification-primary {
  color: #00365a;
  background-color: white;
  border: 1px solid #00365a;
  opacity: 1;

  .notification-message {
    .title {
      color: #00365a;
    }

    .message {
      color: #3a3a3a;
    }
  }

  &.filled {
    color: white;
    background-color: #00365a;

    .notification-message {
      .title {
        color: white;
      }

      .message {
        color: white;
      }
    }
  }
}

.notification-secondary {
  color: #184f90;
  background-color: white;
  border: 1px solid #184f90;
  opacity: 1;

  .notification-message {
    .title {
      color: #184f90;
    }

    .message {
      color: #8f8f8f;
    }
  }

  &.filled {
    color: white;
    background-color: #184f90;

    .notification-message {
      .title {
        color: white;
      }

      .message {
        color: white;
      }
    }
  }
}

.notification-info {
  color: #3195a5;
  background-color: white;
  border: 1px solid #3195a5;
  opacity: 1;

  .notification-message {
    .title {
      color: #3195a5;
    }

    .message {
      color: #8f8f8f;
    }
  }

  &.filled {
    color: white;
    background-color: #3195a5;

    .notification-message {
      .title {
        color: white;
      }

      .message {
        color: white;
      }
    }
  }
}

.notification-success {
  color: #3e884f;
  background-color: white;
  border: 1px solid #3e884f;
  opacity: 1;

  .notification-message {
    .title {
      color: #3e884f;
    }

    .message {
      color: #8f8f8f;
    }
  }

  &.filled {
    color: white;
    background-color: #3e884f;

    .notification-message {
      .title {
        color: white;
      }

      .message {
        color: white;
      }
    }
  }
}

.notification-warning {
  color: #b69329;
  background-color: white;
  border: 1px solid #b69329;
  opacity: 1;

  .notification-message {
    .title {
      color: #b69329;
    }

    .message {
      color: #8f8f8f;
    }
  }

  &.filled {
    color: white;
    background-color: #b69329;

    .notification-message {
      .title {
        color: white;
      }

      .message {
        color: white;
      }
    }
  }
}

.notification-error {
  color: #c43d4b;
  background-color: white;
  border: 1px solid #c43d4b;
  opacity: 1;

  .notification-message {
    .title {
      color: #c43d4b;
    }

    .message {
      color: #8f8f8f;
    }
  }

  &.filled {
    color: white;
    background-color: #c43d4b;

    .notification-message {
      .title {
        color: white;
      }

      .message {
        color: white;
      }
    }
  }
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 13px;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.rounded {
  .notification {
    border-radius: 0.75rem;
  }
}
