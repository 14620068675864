.ql-editor:focus {
    border:1px solid rgb(161 165 183 / var(--tw-border-opacity));
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.ql-toolbar.ql-snow {
    border: 0 !important;
}

.ql-container.ql-snow {
    border: 0 !important;
}

.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
    border: 1px solid #EEEFF3 !important;
    width: 100% !important;
    margin-top: 10px !important;
}