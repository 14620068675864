.row {
  display: flex;
  flex-wrap: wrap;
}
.row > * {
  min-width: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.input-group-text {
  display: inline-flex;
  align-items: center;
  @apply px-2;
  /* first:border-r last:border-l border-light */
}
.text-heading-1 {
  font-size: 21.75px;
  line-height: 26px;
}
.text-heading-2 {
  font-size: 19.5px;
  line-height: 24px;
}
.text-heading-3 {
  font-size: 17.55px;
  line-height: 21px;
}
.text-heading-4 {
  font-size: 16.25px;
  line-height: 20px;
}
.text-heading-5 {
  font-size: 14.95px;
  line-height: 18px;
}
.text-heading-6 {
  font-size: 13.95px;
  line-height: 17px;
}
.text-body-1 {
  font-size: 13px;
  line-height: 16px;
}
.text-body-2 {
  font-size: 12.35px;
  line-height: 15px;
}
.text-body-3 {
  font-size: 11.05px;
  line-height: 13px;
}
.text-body-4 {
  font-size: 9.75px;
  line-height: 12px;
}
