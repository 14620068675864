
$theme-color-1: #345EAC;
$input-background: white;
$separator-color: #A1A5B7;
$border-radius: 10px;

.react-datepicker {
	$parent: &;
	border: none !important;
	background-color: #EFF2F5;
	font-size: 14px !important;
	// font-family: $font-family !important;
	display: flex !important;
	color: #5E6278 !important;

	&__header {
		border-radius: $border-radius $border-radius 0 0 !important;
		background-color: #fff !important;
		border-bottom: initial !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	&__current-month,
	&-time__header {
		text-align: center;
		color: #7E8299 !important;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__navigation {
		top: 15px !important;
	}

	&__time-container {
		border-left: none !important;
		// width: 71px !important;
		background-color: #fff !important;
		box-shadow: 0 5px 5px #A1A5B7;
		border-radius: $border-radius !important;
		margin-left: 10px;

		#{$parent} {
			&__time {
				border-radius: 10px !important;
				// color: $theme-color-1 !important;
			}
		}
	}

	&-popper {
		z-index: 20 !important;
	}

	&-wrapper {
		width: 100% !important;
	}

	&__input-container {
		width: 100% !important;
	}

	&__day-name,
	&__day,
	&__time-name {
		width: 38px !important;
		height: 38px !important;
		border-radius: 5px !important;
		margin: 0 !important;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		outline: initial !important;
	}

	&__day {
		color: #5E6278 !important;

		&:not(.react-datepicker__day--disabled):hover {
			background: #EFF2F5;
		}

		&--keyboard-selected {
			background: $theme-color-1 !important;
			color: white !important;
			&{
				background-color: transparent !important;
				color :#5E6278 !important
			}
			&:hover {
				background: #EFF2F5 !important;
			}
		}

		&-name {
			color: #3F4254 !important;
			font-weight: 500;
		}

		&--today {
			// font-weight: 400 !important;
			background: #fff;
			color: $theme-color-1 !important;
			// box-shadow: 0 5px 5px #A1A5B7;
		}

		&--selected {
			background-color: $theme-color-1 !important;
			// box-shadow: 0 5px 5px rgba($theme-color-1, 0.3);
		}

		&--disabled {
			color: blue !important;
		}
	}

	&__month {
		margin: 10px !important;

		&-container {
			background-color: #fff !important;
			box-shadow: 0 5px 10px #A1A5B7;
			border-radius: $border-radius !important;
		}
	}

	&__triangle {
		left: 30px !important;
		transform: none !important;
		display: none !important;
	}

	&__close-icon {
		right: -7px !important;
		padding: 0 !important;

		&::after {
			content: "×";
			cursor: pointer;
			background-color: $theme-color-1;
			color: #fff;
			border-radius: 50%;
			height: 16px;
			width: 16px;
			font-size: 15px;
			line-height: 1;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
	border-bottom-color: white !important;
	display: none;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
	border-bottom-color: $separator-color !important;
	display: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
	color: white !important;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	border-top-color: white !important;
	display: none;

}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	border-top-color: $separator-color !important;
	display: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
	background: $theme-color-1 !important;
	color: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 35px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
	background-color: $theme-color-1 !important;
	box-shadow: 0 5px 10px rgba($theme-color-1, 0.3);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
	background: $separator-color !important;
}

.react-datepicker.embedded {
	border: initial !important;
	width: 100% !important;

	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		width: 14.28% !important;
	}

	.react-datepicker__month-container {
		width: 100% !important;
	}
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
	width: 85px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
	padding-left: 0 !important;
	scroll-behavior: smooth;
	// @include scrollbar(5px, blue);
	// padding-right: 30px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
	background-color: $theme-color-1 !important;
	box-shadow: 0 5px 10px rgba($theme-color-1, 0.3);
	font-weight: normal !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
	border-color: #7E8299 !important;
	border-width: 2px 2px 0 0 !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 95px !important;
}