.panelmenu  {
    // padding: 16px 8px;
    .panelmenu-header-link{
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    justify-content: space-between;
    // padding: 4px 0 4px 8px;
}
.panelmenu-header-link:focus {
    z-index: 1;
}
.submenu-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.menuitem-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
}
.menuitem-text {
    line-height: 1;
    height: 100%;
    overflow-y: hidden;
}
.panelmenu-header > a {
    // padding: 1.25rem;
    // transition: box-shadow 0.2s;
}
.panelmenu-root-submenu{
//  padding-left: 1rem;
}
.panelmenu-content {
    
    // padding: 0 0 0 1.5rem;
    border-top: 0;
    .menuitem .menuitem-link {
        // padding: 0.75rem 1.25rem;
        border-radius: 0;
        // transition: box-shadow 0.2s;
        user-select: none;
        height: 32px;
    }
    .submenu-list:not(.panelmenu-root-submenu) {
        // padding: 0 0 0 1rem;
        color:#A1A5B7;
    }
}
}

.menuitem{
    margin-bottom: 8px;
}


// .toggleable-content-enter-done {
    //     transform: none;
    // }
    
    .toggleable-content {
        padding-top: 8px;
    }
    
.toggleable-content-enter {
    // opacity: 0;
    // transform: scale(0.9);
    max-height: 0;
    opacity: 0
}

.toggleable-content-enter-active {
    max-height: 1000px;
    opacity: 1;
    // transform: translateX(0);
    transition: max-height 300ms
}
.toggleable-content-exit {
    max-height: 1000px;
    opacity: 1
}

.toggleable-content-exit-active {
    max-height: 0;
    opacity: 0;
    //   transform: scale(0.9);
    transition: max-height 300ms
}

.panelmenu-panel {
    margin-bottom: 16px;
}
.alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms, transform 500ms;
  }
  .menu-active{
        background: rgb(255, 255, 255 , .1);
        border-radius: 8px;
  }

  .submenu-list {
    .submenu-parent {
        padding-bottom: 10px;
      }
  }
  .panelmenu-root-submenu{
    &:first-child {
        padding-top: 10px;             
     }
    &:last-child {
        padding-bottom: 0px;
    } 
  }